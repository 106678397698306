.connection-form-container {
  padding: 30px;
  max-width: 400px;
  width: 100%;
}

.connection-form-container h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: normal;
  color: #686060;
  text-align: left
}

.-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.password-recovery-link{
  padding-left: 110px;
}

.-logo-login{
  width: 40vh;
}

.connectez-vous{
  color: #5d5c5c;
}

.chatbot-inputt {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
  font-size: 16px;
}
.chatbot-input-co{
  font-size: 16px;
  padding: 15px 20px;
  border: 1px solid #ddd;
  width: 90%;
  border-radius: 4px;
}
.error {
  border-color: #ff4d4d;
}

.error-message {
  color: #ff4d4d;
  font-size: 14px;
  margin-top: 5px;
}

.-send-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 15px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  font-weight: bold;
}

.-send-button:hover {
  background-color: #0069d9;
}
.links-container{
  padding-top: 10px;
}