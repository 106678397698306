.navbar {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  padding: 10px;
  background-color: #F3F3F3; /* Red background */
  border-bottom: none; /* Removes bottom border */
}

.navbar-buttons {
  display: flex;
  align-items: center;
}

.reset-button {
  background: none;
  border: 2px solid #747474; /* White border for contrast */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  padding: 5px 10px;
  font-size: 16px;
  color: #747474; /* White text for contrast */
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: 20px;
}

.reset-button:hover {
  color: #ffffff;
  background-color: #cc0000; /* Darker red on hover */
  border-color: #ffffff; /* Maintain white border on hover */
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black; /* Background color for the tooltip */
  color: #F3F3F3;
  font-weight: bold;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position the tooltip below the button */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%; /* Arrow at the top */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}
