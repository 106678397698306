.create-container {
    padding: 30px;
    max-width: 400px;
    width: 100%;
  }

  .create-account {
    margin-top: 0;
    margin-bottom: 25px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #5d5c5c;
  }
  
  .chatbot-input-cr {
    font-size: 16px;
    padding: 15px 20px;
    border: 1px solid #ddd;
    width: 90%;
    border-radius: 4px;
  }
  
  .chatbot-inputt:focus {
    outline: none;
    border-color: #083ab7;
  }
  
  .error {
    border-color: #f04134 !important;
  }
  .cr-logo-login{
    width: 40vh;
  }
  
  .error-message {
    font-size: 0.875rem;
    color: #f04134;
    margin-bottom: 0.5rem;
  }
  
  .send-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
    font-weight: bold;
  }
  
  .send-button:hover {
    background-color: #4e8acf;
  }
  
  .send-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .chat-vyperf {
    font-weight: bold;
    color: #ffffff;
  }
  .modaly {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modaly-content {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    max-width: 80%;
  }
  
  .modaly-title {
    margin: 0 0 10px;
    font-size: 24px;
  }
  
  .modaly-text {
    margin: 0 0 20px;
    font-size: 16px;
  }
  
  .modaly-button {
    display: block;
    margin: 0 auto;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  