  .chatbot-message--user,
  .chatbot-message--bot {
    justify-content: flex-start;
  }
  .-chatbot-message-user-profile{
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  
  .chatbot-message-content {
    background-color: transparent;
    padding-top: 20px;
    width: fit-content;
  }

  .chatbot-message-text pre {
    white-space: pre-wrap;       /* Allows the text to wrap */
    word-wrap: break-word;       /* Ensures long words do not break the layout */
    font-family: inherit;        /* Matches the font style of the surrounding content */
    margin: 0;                   /* Removes default margin */
    background-color: transparent; /* Ensures the background matches the message bubble */
    border: none;                /* Removes any default borders */
    padding: 0;                  /* Adjust padding as needed, or remove default padding */
  }
  .chatbot-message-text strong {
    font-weight: bold;           
    color: #394460;               
  }
  .chatbot-message-text h2, .chatbot-message-text h3 {
    font-weight: bold;
    margin: 10px 0;
  }
  
  .chatbot-message-text h2 {
    font-size: 1.2em;
  }
  
  .chatbot-message-text h3 {
    font-size: 1.1em;
  }
  
  .chatbot-message-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .chatbot-title {
    text-align: right;
  }
  .chatbot-message-text{
    padding-left: 26px;
    order: 0;
    text-align: left;
  }
  .chatbot-message--bot .chatbot-message-text{
    padding-left: 28px;
  }
  .svg {
    width: 28px;
    margin-bottom: -4px;
  }
  .icon-user{
    padding-right: 5px;
    margin-left: 5px;
  }
  .chat-window {
    height: 70vh;
    overflow-y: scroll; 
    overflow-y: auto;
    box-sizing: border-box; 
  }
  
  .chatbot-messages {
    min-height: 100%; 
    height: auto;
  }
  .chatbot-spinner-container{
    padding-left: 25px;
    justify-content: left;
    width: 100%;
  }

  .point {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #000;
    margin-left: 4px;
    animation: dot-animation 1.5s infinite;
  }
  
  @keyframes dot-animation {
    0% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .point:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .point:nth-child(3) {
    animation-delay: 1s;
  }