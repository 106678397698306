.chat-container {
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  height: 85vh;
  padding: 20px;
}

.chat-window-container {
  height: auto;
  width: 75%;
  margin: 0 auto;
  display: flex;
}

@media (max-width: 768px) {
  .chat-window-container {
    width: 92%;
  }
}

.-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.center-logo-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .center-logo-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.center-logo{
  width: 50%;
}

.annimatedMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center; 
  height: 100%; 
}

@media (max-width: 768px) {
  .annimatedMessage {
    height: 100%; 
  }
}

.suggestions-container {
  margin-top: 40px;
  text-align: center;
}

.suggestions-title {
  font-style: italic;
  margin-bottom: 10px;
}

.suggestions-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.suggestion-button {
  margin: 5px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestion-button:hover {
  background-color: rgb(1,123,254);
  color:#f5f5f5;
}
