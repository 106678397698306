.chat-input {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

/* Adjust width for smaller screens */
@media (max-width: 768px) {
  .chat-input {
    width: 95%;
  }
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgb(243, 243, 243);
  border-radius: 20px;
  padding: 5px;
  width: 100%;
}

.chatbot-input {
  width: 100%;
  border: none;
  border-radius: 15px;
  padding: 10px 50px 10px 15px; /* Padding to avoid overlap with the button */
  font-size: 14px;
  font-family: Arial, sans-serif;
  outline: none;
  resize: none; /* Disable manual resizing */
  height: 30px; /* Set smaller initial height */
}
.chatbot-input::placeholder {
  font-style: italic;
  font-family: Arial, sans-serif; /* Set placeholder font to Arial */
}

.chatbot-send-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: rgb(1, 123, 254);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chatbot-send-button:disabled {
  background-color: grey; /* Disabled button color */
  cursor: not-allowed;
}
